<template>
  <div>
    <v-sheet :dark="theme === 'dark'" class="py-4 py-lg-16">
      <v-container fluid class="px-0 py-0">
        <v-row no-gutters>
          <v-col cols="0" lg="2"></v-col>
          <v-col cols="12" lg="6">
            <h2 class="name hidden-lg-and-up px-8">
              {{ $t('meetings.list.meeting-with', bookingWith(appointment)) }}
            </h2>
            <v-carousel :show-arrows="false" :hide-delimiters="true">
              <v-carousel-item :src="bookingWith(appointment).avatar" />
            </v-carousel>
          </v-col>
          <v-col cols="12" lg="3" class="pl-lg-16">
            <h2 class="name hidden-md-and-down">
              {{ $t('meetings.list.meeting-with', bookingWith(appointment)) }}
            </h2>
            <h2 class="date px-8 px-lg-0">
              {{ formatDateTime(appointment) }}
            </h2>
            <p class="description pt-2 px-8 px-lg-0">
              {{ appointment.description }}
            </p>
            <div class="d-flex flex-column align-center">
              <v-btn
                class="mb-4"
                color="primary"
                width="275px"
                outlined
                :dark="theme === 'dark'"
                tile
                x-large
                :disabled="!canAccessAppointment()"
                :to="{
                  name: 'MeetingDetail',
                  params: { eventId: appEvent.id, meetingId: appointment.id },
                }"
                >{{ $t('schedule.actions.participate') }}
              </v-btn>

              <v-btn
                class="mb-4"
                color="primary"
                width="275px"
                outlined
                :dark="theme === 'dark'"
                tile
                x-large
                @click="confirmCancelAppointment()"
                >{{ $t('meetings.list.cancel') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="0" lg="1" />
        </v-row>

        <dialog-confirmation
          :visible="showConfirmation"
          :title="$t('meetings.deleteConfirmationDialog.title')"
          :content="$t('meetings.deleteConfirmationDialog.content', bookingWith(appointment))"
          @cancel="confirmationDialogCancel"
          @confirm="confirmationDialogConfirm"
        />
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';
import {
  bookingWith,
  canAccessAppointment,
  formatAppointmentDate,
} from '../../models/users-appointments/user-event-appointment';
import i18n from '../../i18n';
import DialogConfirmation from '../../views/DialogConfirmation.vue';

export default {
  name: 'MeetingAgenda',
  components: {
    DialogConfirmation,
  },
  props: {
    appEvent: Object,
    appointment: Object,
    now: String,
    theme: String,
  },
  data: () => ({
    showConfirmation: false,
  }),
  computed: {
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    bookingWith() {
      return (appointment) => bookingWith(appointment, this.loggedInUser);
    },
    formatDateTime() {
      return (appointment) => formatAppointmentDate(i18n)(appointment);
    },
    canAccessAppointment() {
      return () => canAccessAppointment(DateTime.fromISO(this.now))(this.appointment);
    },
  },
  methods: {
    confirmCancelAppointment() {
      this.showConfirmation = true;
    },
    confirmationDialogCancel() {
      this.showConfirmation = false;
    },
    async confirmationDialogConfirm() {
      this.$emit('remove-from-agenda', this.appointment);
      this.showConfirmation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.description {
  white-space: pre-line;
}

@include carousel-fix();
</style>
